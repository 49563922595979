import axios from "axios";
import { FhirPlanDefinitionMod } from "models/fhirPlanDefinition";
import {
  FetchFhirPaginationFn,
  FhirPagination,
  FhirPatientPaginationSort,
  FhirServiceRequestPaginationSort,
  IndexedServiceRequestWithTasksNPatientNOrg,
  QueryServiceRequestWithTasks,
  QueryServiceRequestWithTasksNPatientNOrg,
  IndexedTasksWithServiceRequestsAndOwner,
} from "models/fhirQuery";
import {
  getlistResource,
  getPaginatedResource,
  indexQueryServiceRequestWithTasksNPatientNOrg,
  indexResource,
  indexQueryTask,
} from "models/fhirQueryFn";
import { FhirMeasureReportMod } from "models/measureReport";
import {
  AssessmentsResponse,
  OrderDetailsResponse,
  TaskMod,
} from "models/order";
import {
  FhirOrganizationMod,
  HealthcareOrganizationListItemMod,
  HealthcareOrganizationMod,
} from "models/organization";
import { FhirPatientMod, HealthcarePatient } from "models/patient";
import { FhirPractitionerMod } from "models/practitioner";
import { FhirQuestionnaireMod } from "models/questionnaire";
import { FhirQuestionnaireResponseMod } from "models/questionnaireResponse";
import { FhirRelatedPersonMod } from "models/relatedPerson";
import { FhirServiceRequestMod } from "models/serviceRequest";
import { FhirTaskMod } from "models/task";
import { FhirConsentMod } from "models/fhirConsent";
import { FhirValueSetMod } from "models/valueSet";
import { INoteRowInfo } from "components/patient-engagement/NotesList";
import { HasOrganizationData, HasOrganizationResponse } from "models/user";
import {
  urlBackNestApp,
  doFormAssignUrl,
  doFormRegistrationUrl,
  doFormLanguageUrl,
} from "routes/urls";
import {
  APIPagination,
  ApiIndexedTaskPaginationSort,
  FetchAPIPaginationFn,
} from "models/apiQuery";
import { getPaginatedApiResponse } from "../models/apiQueryFn";
import { getBaseUrlNative } from "_helpers";

let defaultHeaders = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
};

function getDefaultHeaders() {
  if (defaultHeaders.Authorization == "Bearer null") {
    console.log("getting token from storage");
    defaultHeaders.Authorization = `Bearer ${localStorage.getItem(
      "encryptUser"
    )}`;
  }
  return defaultHeaders;
}

export async function get<T>(
  path: string,
  args: RequestInit = { method: "GET", headers: getDefaultHeaders() }
): Promise<T> {
  const time1 = performance.now();
  const response = await fetch(path, args);
  const finalResponse = response.json();
  const time2 = performance.now();

  console.log(
    `${((time2 - time1) / 1000).toFixed(2)}s in response this call ${path}`
  );

  return finalResponse;
}

async function post<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: "POST",
    body: JSON.stringify(body),
    headers: getDefaultHeaders(),
  }
): Promise<T> {
  const response = await fetch(path, args);
  return response.json();
}

async function put<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: "PUT",
    body: JSON.stringify(body),
    headers: getDefaultHeaders(),
  }
): Promise<T> {
  const response = await fetch(path, args);
  return response.json();
}

async function patch<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: getDefaultHeaders(),
  }
): Promise<T> {
  const response = await fetch(path, args);
  return response.json();
}

const FhirPractitioner = {
  list: async () => {
    const response = await get<any>(`${urlBackNestApp}/fhirproxy/Practitioner`);

    return getlistResource<FhirPractitionerMod.Practitioner>(
      response,
      "Practitioner"
    );
  },
  details: (uuid: string) =>
    get<FhirPractitionerMod.Practitioner>(
      `${urlBackNestApp}/fhirproxy/Practitioner/${uuid}`
    ),
  /* create: () => ,
    update: () => ,
    delete: () =>  */
};

const FhirOrganization = {
  details: (uuid: string) =>
    get<FhirOrganizationMod.Organization>(
      `${urlBackNestApp}/fhirproxy/Organization/${uuid}`
    ),
};

const fhirPatientPaginatedList: FetchFhirPaginationFn<
  FhirPatientMod.Patient,
  FhirPatientPaginationSort
> = async (
  pageCount: string = "25",
  sortList?: FhirPatientPaginationSort[],
  filter?: string,
  pageToken?: string
): Promise<FhirPagination<FhirPatientMod.Patient>> => {
    let fetchUrl = `${urlBackNestApp}/fhirproxy/Patient?_count=${pageCount}`;

    // filter removed patients
    fetchUrl += "&active=true";

    if (sortList !== undefined && sortList.length > 0) {
      let sortStr = "&_sort=";
      sortList.forEach((item: FhirPatientPaginationSort) => {
        if (item.desc) sortStr += "-";

        sortStr += item.value;
      });

      fetchUrl += sortStr;
    }

    if (filter !== undefined && filter !== "") fetchUrl += `&_content=${filter}`;

    if (pageToken !== undefined)
      fetchUrl += `&_page_token=${encodeURIComponent(pageToken)}`;

    fetchUrl += "&_include:iterate=Patient:organization";

    const response = await get<any>(fetchUrl);

    const resources = getlistResource<FhirPatientMod.Patient>(
      response,
      "Patient"
    );

    return getPaginatedResource<FhirPatientMod.Patient>(response, resources);
  };

const FhirPatient = {
  paginatedList: fhirPatientPaginatedList,
  details: (uuid: string) =>
    get<FhirPatientMod.Patient>(`${urlBackNestApp}/fhirproxy/Patient/${uuid}`),
  detailsWithOrg: (uuid: string) =>
    get<any>(
      `${urlBackNestApp}/fhirproxy/Patient?_id=${uuid}&_include=Patient:organization`
    ),
  updatePatientIdentifier: (uuid: string, data: any = {}) =>
    put<FhirPatientMod.Patient>(`${urlBackNestApp}/healthcare/patient/${uuid}/identifier`, data),
  updatePatientToEnroll: (uuid: string, data: any = {}) =>
    put<FhirPatientMod.Patient>(`${urlBackNestApp}/healthcare/patient/${uuid}/enroll`, data),
  patch: (uuid: string, payload: any) =>
    patch<FhirPatientMod.Patient>(`${urlBackNestApp}/fhirproxy/Patient/${uuid}`, payload),
};

const fhirServiceRequestPaginatedNIndexedListWithTasksNPatientNOrg: FetchFhirPaginationFn<
  IndexedServiceRequestWithTasksNPatientNOrg,
  FhirServiceRequestPaginationSort
> = async (
  pageCount: string = "25",
  sortList?: FhirServiceRequestPaginationSort[],
  filter?: string,
  pageToken?: string,
  status?: string
): Promise<FhirPagination<IndexedServiceRequestWithTasksNPatientNOrg>> => {
    let fetchUrl = `${urlBackNestApp}/fhirproxy/ServiceRequest?_count=${pageCount}&category=${process.env.REACT_APP_CODE_SYSTEM_NJINCK || "NJINCK-NAT"
      }`;

    // filter removed service request
    fetchUrl += "&status:not=revoked";

    if (status !== undefined) fetchUrl += `&status=${status}`;

    if (sortList !== undefined && sortList.length > 0) {
      let sortStr = "&_sort=";
      sortList.forEach((item: FhirServiceRequestPaginationSort) => {
        if (item.desc) sortStr += "-";

        sortStr += item.value;
      });

      fetchUrl += sortStr;
    }

    if (filter !== undefined && filter !== "") fetchUrl += `&_content=${filter}`;

    if (pageToken !== undefined)
      fetchUrl += `&_page_token=${encodeURIComponent(pageToken)}`;

    fetchUrl +=
      "&_revinclude=Task:based-on:ServiceRequest&_include=ServiceRequest:subject:Patient&_include:iterate=Patient:organization";

    const response = await get<any>(fetchUrl);

    const indexedResources = indexQueryServiceRequestWithTasksNPatientNOrg(
      response
    );

    return getPaginatedResource<IndexedServiceRequestWithTasksNPatientNOrg>(
      response,
      indexedResources
    );
  };

const getTasksList = async ({
  pageCount = 25,
  sortList,
  filter,
  pageToken,
  status,
  assigned,
}: any): Promise<any> => {
  let fetchUrl = `${urlBackNestApp}/fhirproxy/Task/?code=completed-nat-assessment&_include:iterate=Task:owner&_include:iterate=Task:based-on&_include:iterate=ServiceRequest:subject&_count=${pageCount}`;

  const PLAN_DEFINITION = "fhirPlanDefinition";
  let response2: any = {};

  if (status !== undefined && Array.isArray(status))
    fetchUrl += `&status=${status.join()}`;

  if (assigned !== undefined && Array.isArray(assigned))
    fetchUrl += `&identifier=${assigned.join()}`;

  if (sortList !== undefined && sortList.length > 0) {
    let sortStr = "&_sort=";
    sortList.forEach((item: FhirServiceRequestPaginationSort) => {
      if (item.desc) sortStr += "-";

      sortStr += item.value;
    });

    fetchUrl += sortStr;
  }

  if (filter !== undefined && filter !== "") fetchUrl += `&_content=${filter}`;

  if (pageToken !== undefined) fetchUrl += `&_page_token=${pageToken}`;

  const response = await get<any>(fetchUrl);

  if (localStorage.getItem(PLAN_DEFINITION)) {
    response2 = JSON.parse(localStorage.getItem(PLAN_DEFINITION));
  } else {
    response2 = await get<any>(`${urlBackNestApp}/fhirproxy/PlanDefinition`);
    localStorage.setItem(PLAN_DEFINITION, JSON.stringify(response2));
  }

  const finalResponse = {
    ...response,
    ...(!!Object.keys(response2).length && {
      entry: response?.entry?.concat(response2.entry),
    }),
  };

  const indexedResources = indexQueryTask(finalResponse);

  return getPaginatedResource<any>(response, indexedResources);
};

const FhirServiceRequest = {
  details: (uuid: string) =>
    get<FhirServiceRequestMod.ServiceRequest>(
      `${urlBackNestApp}/fhirproxy/ServiceRequest/${uuid}`
    ),
  tasksList: getTasksList,
  detailsWithTasks: async (
    uuid: string
  ): Promise<QueryServiceRequestWithTasks> => {
    const response = await put<any>(
      `${urlBackNestApp}/fhirproxy/compositeread?query=ServiceRequest?_id=${uuid}%26_revinclude=Task:based-on:ServiceRequest`,
      {}
    );

    const serviceRequest = response.entry.find(
      (item: any) =>
        item.resource.resourceType === "ServiceRequest" &&
        item.search.mode === "match"
    ).resource as FhirServiceRequestMod.ServiceRequest;

    const tasks = response.entry
      .filter(
        (item: any) =>
          item.resource.resourceType === "Task" &&
          item.search.mode === "include"
      )
      .map((item: any) => item.resource) as FhirTaskMod.Task[];

    const queryServiceRequestWithTasks: QueryServiceRequestWithTasks = {
      serviceRequest: serviceRequest,
      tasks: tasks,
    };

    return queryServiceRequestWithTasks;
  },
  allWithTasksNPatientNOrg: async (): Promise<QueryServiceRequestWithTasksNPatientNOrg> => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/ServiceRequest?_revinclude=Task:based-on:ServiceRequest&_include=ServiceRequest:subject:Patient&_include:iterate=Patient:organization`
    );

    const queryServiceRequestWithTasksNPatientNOrg: QueryServiceRequestWithTasksNPatientNOrg = {
      serviceRequests: [],
      tasks: [],
      patients: [],
      organizations: [],
    };

    response.entry.forEach((item: any) => {
      if (
        item.resource.resourceType === "ServiceRequest" &&
        item.search.mode === "match"
      ) {
        const serviceRequest = item.resource as FhirServiceRequestMod.ServiceRequest;
        queryServiceRequestWithTasksNPatientNOrg.serviceRequests.push(
          serviceRequest
        );
      } else if (
        item.resource.resourceType === "Task" &&
        item.search.mode === "include"
      ) {
        const task = item.resource as FhirTaskMod.Task;
        queryServiceRequestWithTasksNPatientNOrg.tasks.push(task);
      } else if (
        item.resource.resourceType === "Patient" &&
        item.search.mode === "include"
      ) {
        const patient = item.resource as FhirPatientMod.Patient;
        queryServiceRequestWithTasksNPatientNOrg.patients.push(patient);
      } else if (
        item.resource.resourceType === "Organization" &&
        item.search.mode === "include"
      ) {
        const organization = item.resource as FhirOrganizationMod.Organization;
        queryServiceRequestWithTasksNPatientNOrg.organizations.push(
          organization
        );
      }
    });

    return queryServiceRequestWithTasksNPatientNOrg;
  },
  indexedAllWithTasksNPatientNOrg: async (
    filterstatus: any
  ): Promise<IndexedServiceRequestWithTasksNPatientNOrg> => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/ServiceRequest?_count=500&status=${filterstatus}&_revinclude=Task:based-on:ServiceRequest&_include=ServiceRequest:subject:Patient&_include:iterate=Patient:organization`
    );

    return indexQueryServiceRequestWithTasksNPatientNOrg(response);
  },
  paginatedNIndexedListWithTasksNPatientNOrg: fhirServiceRequestPaginatedNIndexedListWithTasksNPatientNOrg,
  listWhereSubjectPatient: async (patientUuid: string) => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/ServiceRequest?subject=${patientUuid}`
    );

    return getlistResource<FhirServiceRequestMod.ServiceRequest>(
      response,
      "ServiceRequest"
    );
  },
};

const FhirTask = {
  details: (uuid: string) =>
    get<FhirTaskMod.Task>(`${urlBackNestApp}/fhirproxy/Task/${uuid}`),
  detailsWithServiceRequest: (uuid: string) =>
    get<FhirTaskMod.Task>(
      `${urlBackNestApp}/fhirproxy/Task?_id=${uuid}&_include:iterate=Task:based-on`
    ),
  byPatientWithServiceRequestAndOwner: async (
    patientUuid: string
  ): Promise<IndexedTasksWithServiceRequestsAndOwner> => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/Task?patient=${patientUuid}&_include:iterate=Task:based-on:ServiceRequest&_include:iterate=Task:owner`
    );

    let indexedTasksWithServiceRequestsAndOwner: IndexedTasksWithServiceRequestsAndOwner = {
      serviceRequests: {},
      tasks: {},
      patientOwner: {},
      relatedPersonOwner: {},
    };

    response?.entry?.forEach((item: any) => {
      if (
        item.resource.resourceType === "Task" &&
        item.search.mode === "match"
      ) {
        const casted = item.resource as FhirTaskMod.Task;
        indexedTasksWithServiceRequestsAndOwner.tasks[`${casted.id}`] = casted;
      } else if (
        item.resource.resourceType === "ServiceRequest" &&
        item.search.mode === "include"
      ) {
        const casted = item.resource as FhirServiceRequestMod.ServiceRequest;
        indexedTasksWithServiceRequestsAndOwner.serviceRequests[
          `${casted.id}`
        ] = casted;
      } else if (
        item.resource.resourceType === "Patient" &&
        item.search.mode === "include"
      ) {
        const casted = item.resource as FhirPatientMod.Patient;
        indexedTasksWithServiceRequestsAndOwner.patientOwner[
          `${casted.id}`
        ] = casted;
      } else if (
        item.resource.resourceType === "RelatedPerson" &&
        item.search.mode === "include"
      ) {
        const casted = item.resource as FhirRelatedPersonMod.RelatedPerson;
        indexedTasksWithServiceRequestsAndOwner.relatedPersonOwner[
          `${casted.id}`
        ] = casted;
      }
    });

    return indexedTasksWithServiceRequestsAndOwner;
  },
  updateAndIndexTask: async (data: any) =>
    await patch<any>(`${urlBackNestApp}/fhirproxy/indexed/Task/${data.id}`, data),
};

const FhirQuestionnaire = {
  list: async (uuids: string[]) => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/Questionnaire?_id=${uuids.join(",")}`
    );

    return getlistResource<FhirQuestionnaireResponseMod.QuestionnaireResponse>(
      response,
      "Questionnaire"
    );
  },
  indexedList: async (uuids: string[]) => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/Questionnaire?_id=${uuids.join(",")}`
    );

    return indexResource<FhirQuestionnaireMod.Questionnaire>(
      response,
      "Questionnaire"
    );
  },
};

const FhirQuestionnaireResponse = {
  list: async (uuids: string[]) => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/QuestionnaireResponse?_id=${uuids.join(",")}`
    );

    return getlistResource<FhirQuestionnaireResponseMod.QuestionnaireResponse>(
      response,
      "QuestionnaireResponse"
    );
  },
  indexedList: async (uuids: string[]) => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/QuestionnaireResponseFilter?_id=${uuids.join(
        ","
      )}` // &_security:not=R
    );

    return indexResource<any>(response, "QuestionnaireResponse");
  },
  indexedListWithSecurity: async (uuids: string[], securityLabel: any) => {
    const response = await get<any>(
      `${urlBackNestApp}/fhirproxy/QuestionnaireResponseFilter?_id=${uuids.join(
        ","
      )}&_security:not=${securityLabel}` // &_security:not=R
    );

    return indexResource<FhirQuestionnaireResponseMod.QuestionnaireResponse>(
      response,
      "QuestionnaireResponse"
    );
  },
};

const FhirMeasureReport = {
  details: (uuid: string) =>
    get<FhirMeasureReportMod.MeasureReport>(
      `${urlBackNestApp}/fhirproxy/MeasureReport/${uuid}`
    ),
};

const FhirObservation = {
  bySubject: (uuid: string) =>
    get<any>(`${urlBackNestApp}/fhirproxy/Observation?subject=${uuid}`),
};

const FhirRelatedPerson = {
  details: (uuid: string) =>
    get<FhirRelatedPersonMod.RelatedPerson>(
      `${urlBackNestApp}/fhirproxy/RelatedPerson/${uuid}`
    ),
};

const FhirPlanDefinition = {
  details: (uuid: string) =>
    get<FhirPlanDefinitionMod.PlanDefinition>(
      `${urlBackNestApp}/fhirproxy/PlanDefinition/${uuid}`
    ),
};

const Order = {
  details: (id: string) =>
    get<OrderDetailsResponse>(`${urlBackNestApp}/order/${id}`),
  getTask: (patientId: string) =>
    get<TaskMod.TaskResponse>(`${urlBackNestApp}/order/task/${patientId}`),
  getAssessment: (taskId: string) =>
    get<AssessmentsResponse.Assessment>(
      `${urlBackNestApp}/order/assesment/${taskId}`
    ),
  resendTask: (taskId: string) =>
    post<TaskMod.TaskResponse>(
      `${urlBackNestApp}/order/task/${taskId}/resend`,
      {}
    ),
  getPrintable: async (printableTaskData: PrintableTaskMod.Task) => {
    const response = await axios.post<any>(
      `${urlBackNestApp}/order/printable`,
      printableTaskData,
      { responseType: "arraybuffer", timeout: 20000 }
    );
    return response;
  },
  reprocess: (taskId: string) =>
    post<TaskMod.TaskResponse>(
      `${urlBackNestApp}/order/task/${taskId}/reprocess`,
      {}
    ),
};

const Registry = {
  getByCode: (code: any) =>
    get<OrderDetailsResponse>(`${urlBackNestApp}/order/registry/${code}`),
};

const Communication = {
  addNote: (data: any) =>
    post<any>(`${urlBackNestApp}/fhirproxy/Communication`, data),
  getNote: (patientId: string, category: string = 'Notes') =>
    get<any>(`${urlBackNestApp}/fhirproxy/Communication/?subject=${patientId}&category=${category}`),
  updateNote: (data: any) =>
    patch<any>(`${urlBackNestApp}/fhirproxy/Communication/${data.id}`, data),
};

const Binary = {
  add: (data: any) => post<any>(`${urlBackNestApp}/fhirproxy/Binary`, data),
};

const DocumentReference = {
  add: (data: any) =>
    post<any>(`${urlBackNestApp}/fhirproxy/DocumentReference`, data),
};

const Patient = {
  allpatients: () =>
    get<HealthcarePatient[]>(
      `${urlBackNestApp}/healthcare/patient/allpatients`
    ),
  fhirPatch: (patient: FhirPatientMod.Patient) => {
    if (patient.id === undefined) return;

    return patch<any>(
      `${urlBackNestApp}/healthcare/patient/fhir/${patient.id}`,
      patient
    );
  },
  getStates: () => {
    return get<any>(`${urlBackNestApp}/healthcare/patient/get-states`);
  },
  get: (id: string) =>
    get<HealthcarePatient>(`${urlBackNestApp}/healthcare/patient/${id}`),
};

const Organization = {
  list: () =>
    get<HealthcareOrganizationListItemMod.Organization[]>(
      `${urlBackNestApp}/healthcare/allorganizations`
    ),
  get: (id: string) =>
    get<HealthcareOrganizationMod.Organization>(
      `${urlBackNestApp}/healthcare/organization/${id}`
    ),
};

const Healthcare = {
  Patient: Patient,
  Organization: Organization,
};

const User = {
  allUser: () => get<any>(`${urlBackNestApp}/users/all-users`),
  allUserByAcls: (acls: string[]) => {
    const url = new URL(`${urlBackNestApp}/users/all-users-by-acls`);
    const params = new URLSearchParams({ acls: acls.join(",") });
    url.search = params.toString();
    return get<any>(url);
  },
  hasOrganization: (data: HasOrganizationData) =>
    post<HasOrganizationResponse>(
      `${urlBackNestApp}/users/has-organization`,
      data
    ),
  getInfo: (email: any) => get<any>(`${urlBackNestApp}/users/${email}`),
};

const FhirPatientConsent = {
  details: (uuid: string) =>
    get<FhirConsentMod.FhirConsent>(
      `${urlBackNestApp}/fhirproxy/consent/${uuid}`
    ),
  byPatient: (patientid: string) =>
    get<FhirConsentMod.FhirConsent>(
      `${urlBackNestApp}/fhirproxy/Consent?patient=${patientid}`
    ),
};
const FhirValueSet = {
  details: (id: string) =>
    get<FhirValueSetMod.ValueSet>(`${urlBackNestApp}/fhirproxy/ValueSet/${id}`),
};

const PatientEngagement = {
  getPatientById: (id: any) =>
    get<any>(`${urlBackNestApp}/patientengagement/${id}`),
  getPatientByEmail: (email: any) =>
    get<any>(`${urlBackNestApp}/patientengagement/email/${email}`),
  getPatientByFhirId: (id: any) =>
    get<any>(`${urlBackNestApp}/patientengagement/fhir/${id}`),
  getPatientContactAttempt: (id: any) =>
    get<any>(
      `${urlBackNestApp}/patientengagement/contact-attempts?patient=${id}`
    ),
  createPatientEngagement: (data: any) =>
    post<any>(`${urlBackNestApp}/patientengagement/new`, data),
  updatePatientById: (id: any, data: any) =>
    put<any>(`${urlBackNestApp}/patientengagement/${id}`, data),
  customUpdate: (data: any) =>
    post<any>(`${urlBackNestApp}/patientengagement/customUpdate`, data),
  checkitem: (data: any) =>
    post<any>(`${urlBackNestApp}/patientengagement/checkitem`, data),
  postNote: (data: INoteRowInfo) =>
    post<INoteRowInfo>(`${urlBackNestApp}/notes/patient`, data),
  getNotes: async (patientEngagementId: string): Promise<INoteRowInfo[]> => {
    const response = await get<any>(
      `${urlBackNestApp}/notes/patient/${patientEngagementId}`
    );

    if (response.statusCode === 404) return [];

    return response as INoteRowInfo[];
  },
  savePatientContactAttempt: (data: any) =>
    post<any>(`${urlBackNestApp}/patientengagement/contact-attempts/`, data),
  complementaryInfo: (data: any) =>
    post<any>(`${urlBackNestApp}/patientengagement/complementary-info/`, data),
  bulkAssign: (data: any) =>
    post<any>(`${urlBackNestApp}/patientengagement/bulkAssign`, data),
};

const Workflow = {
  getWorkflowByCode: (code: any) =>
    get<any>(`${urlBackNestApp}/workflow/?code=${code}`),
  getTransitions: (workFlow: any) =>
    get<any>(`${urlBackNestApp}/workflow/transitions?workFlow=${workFlow}`),
};

const Checklist = {
  getChecklistById: (data: any) =>
    post<any>(`${urlBackNestApp}/checklist/`, data),
};

const Transition = {
  attempt: (data: any) =>
    post<any>(`${urlBackNestApp}/transition/attempt/`, data),
  updateStage: (data: any) =>
    put<any>(`${urlBackNestApp}/transition/stage/`, data),
};

const Catalog = {
  getByCode: (code: string) => get<any>(`${urlBackNestApp}/catalog/${code}`),
};
const AppRegistry = {
  getValue: (key: string) => get<any>(`${urlBackNestApp}/app-registry/${key}`),
};
const getApiIndexedTasksList = async ({
  pageCount = 25,
  sortList,
  filter,
  page,
  status,
  assigned,
}: any): Promise<any> => {
  // let fetchUrl = `${urlBackNestApp}/fhirproxy/Task/?code=completed-nat-assessment&_include:iterate=Task:owner&_include:iterate=Task:based-on&_include:iterate=ServiceRequest:subject&_count=${pageCount}`;
  let fetchUrl = `${urlBackNestApp}/order/task?take=${pageCount}`;

  console.log("DEBUG getApiIndexedTasksList pageCount: ", pageCount);
  console.log("DEBUG getApiIndexedTasksList sortList: ", sortList);
  console.log("DEBUG getApiIndexedTasksList filter: ", filter);
  console.log("DEBUG getApiIndexedTasksList page: ", page);
  console.log("DEBUG getApiIndexedTasksList status: ", status);
  console.log("DEBUG getApiIndexedTasksList assigned: ", assigned);

  if (status !== undefined && Array.isArray(status))
    fetchUrl += `&status=${status.join()}`;

  if (assigned !== undefined && Array.isArray(assigned))
    fetchUrl += `&assigned=${assigned.join()}`;

  if (sortList !== undefined && sortList.length > 0) {
    let sortStr = "";
    sortList.forEach((item: FhirServiceRequestPaginationSort) => {
      console.log("DEBUG getApiIndexedTasksList sortItem: ", item);
      if (item.desc) sortStr += `&sort=${item.value}&order=desc`;
      if (!item.desc) sortStr += `&sort=${item.value}&order=asc`;
    });

    fetchUrl += sortStr;
  }

  if (filter !== undefined && filter !== "") fetchUrl += `&search=${filter}`;

  if (page !== undefined) fetchUrl += `&page=${page}`;

  console.log("DEBUG fetchUrl: ", fetchUrl);
  const response = await get<any>(fetchUrl);

  const paginatedResponse = getPaginatedApiResponse<any>(response);

  console.log("DEBUG paginatedResponse: ", paginatedResponse);

  return paginatedResponse;
};

// const getApiIndexedPatientList = async ({
//   pageCount = 25,
//   sortList,
//   filter,
//   page,
// }: any): Promise<any> => {
//   // let fetchUrl = `${urlBackNestApp}/fhirproxy/Task/?code=completed-nat-assessment&_include:iterate=Task:owner&_include:iterate=Task:based-on&_include:iterate=ServiceRequest:subject&_count=${pageCount}`;
//   let fetchUrl = `${urlBackNestApp}/healthcare/patient/allpatientsindpatient?take=${pageCount}`;

//   console.log("DEBUG getApiIndexedTasksList pageCount: ", pageCount);
//   console.log("DEBUG getApiIndexedTasksList sortList: ", sortList);
//   console.log("DEBUG getApiIndexedTasksList filter: ", filter);
//   console.log("DEBUG getApiIndexedTasksList page: ", page);

//   // if (status !== undefined && Array.isArray(status))
//   //   fetchUrl += `&status=${status.join()}`;

//   // if (assigned !== undefined && Array.isArray(assigned))
//   //   fetchUrl += `&assigned=${assigned.join()}`;


//   if (sortList !== undefined && sortList.length > 0) {
//     let sortStr = "";
//     sortList.forEach((item: any) => {
//       console.log("DEBUG getApiIndexedTasksList sortItem: ", item);
//       if (item.desc) sortStr += `&sort=${item.value}&order=DESC`;
//       if (!item.desc) sortStr += `&sort=${item.value}&order=ASC`;
//     });

//     fetchUrl += sortStr;
//   }

//   if (filter !== undefined && filter !== "") fetchUrl += `&search=${filter}`;

//   if (page !== undefined) fetchUrl += `&page=${page}`;

//   console.log("DEBUG fetchUrl: ", fetchUrl);
//   const response = await get<any>(fetchUrl);

//   const paginatedResponse = getPaginatedApiResponse<any>(response)

//   console.log('DEBUG paginatedResponse: ', paginatedResponse)

//   return paginatedResponse;
// };

const ApiIndexedTask = {
  tasksList: getApiIndexedTasksList,
};
// const ApiIndexedPatient = {
//   PatientList: getApiIndexedPatientList,
// };

// const indexedTasksPaginatedList: FetchAPIPaginationFn<
//   FhirPatientMod.Patient,
//   ApiIndexedTaskPaginationSort
// > = async (
//   pageCount: string = "25",
//   sortList?: ApiIndexedTaskPaginationSort[],
//   filter?: string,
//   pageToken?: string
// ): Promise<APIPagination<FhirPatientMod.Patient>> => {
//     let fetchUrl = `${urlBackNestApp}/fhirproxy/Patient/?_count=${pageCount}`;

//     // filter removed patients
//     fetchUrl += "&active=true";

//     if (sortList !== undefined && sortList.length > 0) {
//       let sortStr = "&_sort=";
//       sortList.forEach((item: ApiIndexedTaskPaginationSort) => {
//         if (item.desc) sortStr += "-";

//         sortStr += item.value;
//       });

//       fetchUrl += sortStr;
//     }

//     if (filter !== undefined && filter !== "") fetchUrl += `&_content=${filter}`;

//     if (pageToken !== undefined)
//       fetchUrl += `&_page_token=${encodeURIComponent(pageToken)}`;

//     fetchUrl += "&_include:iterate=Patient:organization";

//     const response = await get<any>(fetchUrl);

//     const resources = getlistResource<FhirPatientMod.Patient>(
//       response,
//       "Patient"
//     );

//     return getPaginatedApiResponse<FhirPatientMod.Patient>(response, resources);
// };

const ApiDoForm = {
  assign: async (taskId: string, user: string) => {
    console.log("DEBUG doForm assign: ", doFormAssignUrl);
    const payload = { uuid: taskId, assigned_cim1: user };
    const args = {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    };
    return put<any>(doFormAssignUrl, payload, args);
  },
  register: async (payload: any) => {
    console.log("DEBUG doForm Register: ", doFormRegistrationUrl);
    const args = {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    };
    return put<any>(doFormRegistrationUrl, payload, args);
  },
  getLanguageByFormId: async (id: any) => {
    const args = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return get<any>(`${doFormLanguageUrl}?id=${id}`, args);
  },
};

const ApiIncident = {
  update: (id: any, data: any) =>
    put<any>(`${urlBackNestApp}/allincidents/${id}`, data),
};

const PatientPractitioner = {
  list: async (searchText: any, statusValue: any, setRowdata: any) => {
    let fetchurl: string =
      (getBaseUrlNative(
        process.env.REACT_APP_ORGANIZATIONS_ENDPOINT,
        `/healthcare/practitioner/getAllPractitionerAndPractitionerRole/?status=${statusValue}&search=${searchText}`
      ) as any) || ``;

    // if (sortValue?.length > 0) {
    //     fetchurl = buildSortParams(fetchurl, sortValue);
    // }
    fetch(`${fetchurl}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        // console.log(data);
        if (!data.error) {
          console.log(data)
          for (const item of data) {
            item.assignedRole = "";
            item.assignedOrg = "";

          }
          console.log(data)

          setRowdata(data)
        } else {
          // setRowdata([]);
          // setLoading(false)

        }
      })
      .catch((err) => {
        console.log(err);
      });
    // return await get<any>(`${urlBackNestApp}/healthcare/allpractitioner?status=${statusValue}&search=${searchText}`);
  },
  // details: (uuid: string) =>
  //   get<FhirPractitionerMod.Practitioner>(
  //     `${urlBackNestApp}/fhirproxy/Practitioner/${uuid}`
  //   ),
  /* create: () => ,
    update: () => ,
    delete: () =>  */

};

const PractitionerRole = {
  get: (id: any) =>
    get<any>(
      `${urlBackNestApp}/fhirproxy/PractitionerRole/${id}`
    ),
  post: (data: any) =>
    post<any>(`${urlBackNestApp}/fhirproxy/PractitionerRole`, data),
  patch: (uuid: string, payload: any) =>
    patch<any>(`${urlBackNestApp}/fhirproxy/PractitionerRole/${uuid}`, payload),
};
const defaultConsts = {
  FhirRelatedPerson,
  FhirMeasureReport,
  FhirObservation,
  FhirPractitioner,
  FhirPatient,
  FhirTask,
  FhirOrganization,
  FhirQuestionnaire,
  FhirQuestionnaireResponse,
  FhirPlanDefinition,
  FhirServiceRequest,
  FhirPatientConsent,
  FhirValueSet,
  ApiIndexedTask,
  // ApiIndexedPatient,
  PatientEngagement,
  Order,
  Communication,
  Binary,
  DocumentReference,
  Healthcare,
  User,
  Patient,
  Registry,
  Workflow,
  Checklist,
  Transition,
  Catalog,
  AppRegistry,
  ApiDoForm,
  ApiIncident,
  PatientPractitioner,
  PractitionerRole
  // AppRegistry,

};

export default defaultConsts;
