import { useEffect, useState } from "react";
import { FhirDataTypesMod } from "../models/fhirDataTypes";
import { FhirPlanDefinitionMod } from "../models/fhirPlanDefinition";
import { indexedInt } from "../models/fhirQuery";
import { FhirMeasureReportMod } from "../models/measureReport";
import { FhirOrganizationMod } from "../models/organization";
import { FhirPatientMod } from "../models/patient";
import { FhirPractitionerMod } from "../models/practitioner";
import { FhirQuestionnaireMod } from "../models/questionnaire";
import { FhirQuestionnaireResponseMod } from "../models/questionnaireResponse";
import { FhirRelatedPersonMod } from "../models/relatedPerson";
import { FhirServiceRequestMod } from "../models/serviceRequest";
import { FhirTaskMod } from "../models/task";
import { OwnerData } from "../pages/orders/OtherInfoDetails";
import { urlBackNestApp } from "../routes/urls";
import agent from '../api/agent';
import {
  getRisk,
  MeasureReportGroups,
  Risks,
} from "../pages/orders/RequestReport";
import {
  dateYearFormatWithAge,
  getAgeFromString,
  phoneFormat,
  parseDateUsingFormat,
} from "../utils/datesFormats";
import {
  getEmailFromTelecom,
  getEthnicityFromPatient,
  getMeasureReportGroups,
  getMRNFromIdentifiers,
  getNPIFromIdentifiers,
  getPhoneFromTelecom,
  getRaceFromPatient,
  getSplitReference,
  getTaskNumberFromIdentifiers2,
} from "../utils/fhirUtils";

interface UsePrintableTaskInfoProps {
  fhirServiceRequest: FhirServiceRequestMod.ServiceRequest;
  fhirPractitioner?: FhirPractitionerMod.Practitioner;
  fhirPatient?: FhirPatientMod.Patient;
  fhirOrganization?: FhirOrganizationMod.Organization;
  fhirGeneralPractitioner?: FhirPractitionerMod.Practitioner;
  fhirTask?: FhirTaskMod.Task;
  fhirPlanDefinition?: FhirPlanDefinitionMod.PlanDefinition;
  beneficiary?: FhirPatientMod.Patient;
  guarantor?: FhirRelatedPersonMod.RelatedPerson;
  measureReport?: FhirMeasureReportMod.MeasureReport;
  indexedFhirQuestionnaires?: indexedInt<FhirQuestionnaireMod.Questionnaire>;
  indexedfhirQuestionnaireResponses?: indexedInt<FhirQuestionnaireResponseMod.QuestionnaireResponse>;
  showMeasureQR?: boolean;
  indexedAnswerMaps?: indexedInt<
    FhirQuestionnaireResponseMod.QuestionnaireAnswerMap[]
  >;
}

export default function usePrintableTaskInfo({
  fhirServiceRequest,
  fhirPatient,
  fhirPractitioner,
  fhirOrganization,
  fhirGeneralPractitioner,
  fhirTask,
  fhirPlanDefinition,
  beneficiary,
  guarantor,
  measureReport,
  indexedFhirQuestionnaires,
  indexedfhirQuestionnaireResponses,
  showMeasureQR,
  indexedAnswerMaps,
}: UsePrintableTaskInfoProps) {
  const [riskColors, setRiskColors] = useState("[]");
  const [race, setRace] = useState("");
  const [ethnicity, setEthnicity] = useState("");

  const COLORS_RISK = 'COLORS_RISK';

  useEffect(() => {
    fetchColorsFromRegistry();
  }, []);

  const fetchColorsFromRegistry = async() => {
    const { keyValue: colorsRiks = [] }: any = await agent.Registry.getByCode(COLORS_RISK);
    setRiskColors(colorsRiks);
  };

  const initialPatient: PrintableTaskMod.Patient = {
    name: "",
    birth_date: "",
    age: "",
    mrn: "",
    gender: "",
    phone: "",
    email: "",
    race: "",
    ethnicity: "",
    language: "",
  };

  const initialRequest: PrintableTaskMod.Request = {
    req_number: "",
    req_date: "",
    req_status: "",
    req_plan: "",
    task_no: "",
    task_status: "",
    task_priority: "",
    owner_type: "",
    owner_name: "",
    owner_phone: "",
    owner_email: "",
    task_completion_date: "",
    task_uuid: "",
    task_meta_version_id: "",
  };

  const initialRequester: PrintableTaskMod.Requester = {
    type: "",
    name: "",
    npi: "",
    phone: "",
    email: "",
    created_by: "",
  };

  const initialComplexityScore: PrintableTaskMod.ComplexityScore = {
    color: "",
    value: "",
  };

  //adding OptIn to Payload
  const [consent, setConsent] = useState<string>();
  const patientId = fhirPatient?.id;

  useEffect(() => {
    getConsent();
  }, [patientId]);

  const getConsent = async () => {
    if (patientId) {
      const response: any = await agent.FhirPatientConsent.byPatient(patientId);
      if (
        response?.["entry"]?.[0]?.["resource"]?.["policyRule"]?.[
          "coding"
        ]?.[0]?.["code"]
      ) {
        if (
          response["entry"][0]["resource"]["policyRule"]["coding"][0][
            "code"
          ] === "OIC" ||
          response["entry"][0]["resource"]["policyRule"]["coding"][0][
            "code"
          ] === "OIS"
        ) {
          setConsent(
            "Consent: I want to tell my HealthStory and will share my personal information so I may receive NJ InCK services."
          );
        }
        if (
          response["entry"][0]["resource"]["policyRule"]["coding"][0][
            "code"
          ] === "OOC" ||
          response["entry"][0]["resource"]["policyRule"]["coding"][0][
            "code"
          ] === "OOS"
        ) {
          setConsent(
            "Consent: I don’t want to share my HealthStory personal information. By not sharing, I will not be eligible for NJ InCK services."
          );
        }
      }
    }
  };
  //

  const getRaceAndEthnicity = async (fhirPatient: FhirPatientMod.Patient) => {
    const getRace = await getRaceFromPatient(fhirPatient);
    const getEthnicity = await getEthnicityFromPatient(fhirPatient);
    setRace(getRace);
    setEthnicity(getEthnicity);
  };

  const initialData: PrintableTaskMod.Task = {
    patient: initialPatient,
    request: initialRequest,
    requester: initialRequester,
    medical_complexity: initialComplexityScore,
    social_complexity: initialComplexityScore,
    service_integration_levels: initialComplexityScore,
    measure_report: [],
    questionnaire_response_list: [],
    consent: "Opt In: Not available",
  };

  const [printableTask, setPrintableTask] = useState<PrintableTaskMod.Task>(
    initialData
  );

  useEffect(() => {
    if (fhirPatient === undefined) return;

    getRaceAndEthnicity(fhirPatient);

    setPrintableTask((prev: any) => {
      const mrn =
        fhirPatient.identifier === undefined
          ? undefined
          : getMRNFromIdentifiers(fhirPatient.identifier);

      const phone = fhirPatient.telecom?.filter(
        (item: FhirDataTypesMod.ContactPoint) => item.system === "phone"
      )?.[0]?.value;

      const email = fhirPatient.telecom?.filter(
        (item: FhirDataTypesMod.ContactPoint) => item.system === "email"
      )?.[0]?.value;

      const gender = fhirPatient.gender ? fhirPatient.gender : "";

      return {
        ...prev,
        patient: {
          ...prev.patient,
          name: `${fhirPatient.name?.[0].given?.[0]} ${fhirPatient.name?.[0].family}`,
          birth_date: fhirPatient.birthDate
            ? dateYearFormatWithAge(fhirPatient.birthDate).split("(")[0]
            : "",
          age: fhirPatient.birthDate
            ? `${getAgeFromString(fhirPatient.birthDate)} years`
            : "",
          mrn: mrn ? mrn : "",
          gender: gender,
          phone: phone ? phoneFormat(phone) : "",
          email: email ? email : "",
          race: race,
          ethnicity: ethnicity,
          language: fhirPatient.communication?.[0].language.text
            ? fhirPatient.communication?.[0].language.text
            : "",
        },
      };
    });
  }, [fhirPatient]);

  useEffect(() => {
    if (fhirServiceRequest === undefined) return;

    const request_numer = fhirServiceRequest?.identifier?.find((item) => item?.system?.slice(-29) === "njinck-service-request-number") || {};

    setPrintableTask((prev) => ({
      ...prev,
      request: {
        ...prev.request,
        req_date: fhirServiceRequest?.authoredOn
          ? new Date(fhirServiceRequest?.authoredOn).toDateString()
          : "",
        req_number: request_numer?.value || "",
        req_status: fhirServiceRequest?.status
          ? fhirServiceRequest?.status
          : "",
      },
    }));
  }, [fhirServiceRequest]);

  useEffect(() => {
    if (fhirPlanDefinition === undefined) return;

    setPrintableTask((prev) => ({
      ...prev,
      request: {
        ...prev.request,
        req_plan:
          fhirPlanDefinition?.title === undefined
            ? ""
            : fhirPlanDefinition?.title,
      },
    }));
  }, [fhirPlanDefinition]);

  useEffect(() => {
    if (fhirTask === undefined) return;

    setPrintableTask((prev) => {
      const taskNumber = (() => {
        if (fhirTask?.identifier === undefined) return "";
        const res = getTaskNumberFromIdentifiers2(fhirTask.identifier);
        return res === undefined ? "" : res;
      })();

      return {
        ...prev,
        request: {
          ...prev.request,
          task_id: fhirTask?.id || "",
          task_no: taskNumber,
          task_status: fhirTask?.status ? fhirTask?.status : "",
          task_uuid: fhirTask?.id || "",
          task_meta_version_id: fhirTask?.meta?.versionId || "",
          task_priority: fhirTask?.priority ? fhirTask?.priority : "",
        },
        requester: {
          ...prev.requester,
          created_by:
            fhirTask?.identifier?.[0]?.value === undefined
              ? ""
              : fhirTask?.identifier?.[0]?.value,
        },
      };
    });
  }, [fhirTask]);

  const setOwnerData = (
    owner: FhirPatientMod.Patient | FhirRelatedPersonMod.RelatedPerson
  ) => {
    const ownerDisplay: OwnerData = {
      name: "",
      phone: "",
      email: "",
    };

    if (owner.name?.[0]?.given?.[0] !== undefined)
      ownerDisplay.name = owner.name?.[0]?.given?.[0];

    if (owner.telecom !== undefined) {
      const phone = getPhoneFromTelecom(owner.telecom);

      if (phone !== undefined) ownerDisplay.phone = phoneFormat(phone);

      const email = getEmailFromTelecom(owner.telecom);

      if (email !== undefined) ownerDisplay.email = email;
    }

    setPrintableTask((prev) => {
      return {
        ...prev,
        request: {
          ...prev.request,
          owner_name: ownerDisplay.name,
          owner_phone: ownerDisplay.phone,
          owner_email: ownerDisplay.email,
        },
      };
    });
  };

  useEffect(() => {
    if (beneficiary === undefined) return;

    setPrintableTask((prev) => {
      return {
        ...prev,
        request: {
          ...prev.request,
          owner_type: "Beneficiary",
        },
      };
    });
    setOwnerData(beneficiary);
  }, [beneficiary]);

  useEffect(() => {
    if (guarantor === undefined) return;

    setPrintableTask((prev) => {
      return {
        ...prev,
        request: {
          ...prev.request,
          owner_type: "Guarantor",
        },
      };
    });
    setOwnerData(guarantor);
  }, [guarantor]);

  useEffect(() => {
    if (fhirPractitioner === undefined) return;

    setPrintableTask((prev) => {
      const practitionerNPI =
        fhirPractitioner.identifier === undefined
          ? undefined
          : getNPIFromIdentifiers(fhirPractitioner.identifier);

      return {
        ...prev,
        requester: {
          ...prev.requester,
          type: "Practitioner",
          name: `${fhirPractitioner?.name?.[0]?.given} ${fhirPractitioner?.name?.[0]?.family}`,
          npi: practitionerNPI === undefined ? "" : practitionerNPI,
          phone:
            fhirPractitioner?.telecom?.[1]?.value === undefined
              ? ""
              : phoneFormat(fhirPractitioner.telecom[1].value),
          email:
            fhirPractitioner?.telecom?.[0]?.value === undefined
              ? ""
              : fhirPractitioner.telecom[0].value,
        },
      };
    });
  }, [fhirPractitioner]);

  useEffect(() => {
    if (fhirOrganization === undefined) return;

    setPrintableTask((prev) => {
      const orgNpi =
        fhirOrganization.identifier === undefined
          ? ""
          : getNPIFromIdentifiers(fhirOrganization.identifier);

      return {
        ...prev,
        requester: {
          ...prev.requester,
          type: "Organization",
          name:
            fhirOrganization.name === undefined ? "" : fhirOrganization.name,
          npi: orgNpi === undefined ? "" : orgNpi,
          phone:
            fhirOrganization.contact?.[1]?.telecom?.[0]?.value === undefined
              ? ""
              : phoneFormat(fhirOrganization.contact?.[1]?.telecom?.[0]?.value),
          email:
            fhirOrganization.contact?.[0]?.telecom?.[0]?.value === undefined
              ? ""
              : fhirOrganization.contact?.[0]?.telecom?.[0]?.value,
        },
      };
    });
  }, [fhirOrganization]);

  interface RiskColorPalette {
    backgroundColor: string;
    internalBackgroundColor: string;
    textColor: string;
  }

  const getRiskColorPalette = (value: Risks) => {
    // const currentColor = JSON.parse(riskColors)?.filter((item: any) => item.name === value)[0] || "";
    const colorsFromDB = JSON.parse(riskColors);
    const getColor = colorsFromDB?.find((item: any) => item.name === value);

    const riskColorPalette: RiskColorPalette = {
      backgroundColor: getColor?.value?.backgroundColor || '#FFFFFF',
      internalBackgroundColor: getColor?.value?.backgroundColor || '#FFFFFF',
      textColor: getColor?.value?.color || '#000000',
    };

    return riskColorPalette;

    /*let riskColorPalette2: RiskColorPalette = {
      backgroundColor: "#f57c00",
      internalBackgroundColor: "#f57c00",
      textColor: "#000000",
    };

    switch (value) {
      case "indeterminate-risk":
        riskColorPalette.backgroundColor = "#9c27b0";
        riskColorPalette.internalBackgroundColor = "#9c27b0";
        riskColorPalette.textColor = "#ffffff";
        return riskColorPalette;
      case "none":
        riskColorPalette.backgroundColor = "#f57c00";
        riskColorPalette.internalBackgroundColor = "#f57c00";
        riskColorPalette.textColor = "#000000";
        return riskColorPalette;
      case "low-risk":
        riskColorPalette.backgroundColor = "#229a16";
        riskColorPalette.internalBackgroundColor = "#229a16";
        riskColorPalette.textColor = "#ffffff";
        return riskColorPalette;
      case "medium-risk":
        riskColorPalette.backgroundColor = "#ffc107";
        riskColorPalette.internalBackgroundColor = "#ffc107";
        riskColorPalette.textColor = "#000000";
        return riskColorPalette;
      case "high-risk":
        riskColorPalette.backgroundColor = "#ff4842";
        riskColorPalette.internalBackgroundColor = "#ff4842";
        riskColorPalette.textColor = "#ffffff";
        return riskColorPalette;
      default:
        return riskColorPalette;
    }*/
  };

  const getStratumData = (stratum: FhirMeasureReportMod.Stratum) => {
    const riskText = stratum.component?.[0]?.code?.coding?.[0]?.display || "";
    // const currentColor = JSON.parse(riskColors)?.filter((item: any) => item.name === riskText)[0] || "";
    const colorsFromDB = JSON.parse(riskColors);
    const getColor = colorsFromDB?.find((item: any) => item.name === riskText);
    const riskColorPalette = getRiskColorPalette(
      getRisk(
        stratum.component?.[0]?.code?.coding?.[0]?.code ?? "indeterminate-risk"
      )
    );
    const printableStratumData: PrintableTaskMod.Stratifier = {
      stratifier_text: stratum.component?.[1]?.value?.text
        ? stratum.component?.[1]?.value?.text
        : "",
      risk_text: stratum.component?.[0]?.code?.coding?.[0]?.display || "",
      risk_color: getColor?.value?.backgroundColor || "#FFFFFF",
      risk_note:
        (stratum?.component?.[0]?.value?.coding?.[0]?.display !==
          stratum?.component?.[0]?.code?.coding?.[0]?.code &&
          stratum?.component?.[0]?.value?.coding?.[0]?.display) ||
        "",
      score:
        stratum.measureScore?.value === undefined
          ? ""
          : String(stratum.measureScore?.value),
    };

    return printableStratumData;
  };

  const getPrintableQuestionnaireResponseItems = (
    questResItem:
      | FhirQuestionnaireResponseMod.Item
      | FhirQuestionnaireResponseMod.Item[],
    questionnaireId: string
  ) => {
    let printableItems: PrintableTaskMod.Item[] = [];
    if (Array.isArray(questResItem)) {
      questResItem.forEach((item: FhirQuestionnaireResponseMod.Item) => {
        const rList = getPrintableQuestionnaireResponseItems(
          item,
          questionnaireId
        );
        const mergedList = printableItems.concat(rList);
        printableItems = mergedList;
      });
    } else {
      if (questResItem.answer) {
        let printableItem: PrintableTaskMod.Item = {
          text: questResItem.text === undefined ? "" : questResItem.text,
          response: "",
          responseExplanation: "",
        };

        questResItem.answer?.forEach(
          (item: FhirQuestionnaireResponseMod.Answer, index: number) => {
            if (index > 0) {
              printableItem.response += ", ";
            }
            const answerMaps = indexedAnswerMaps?.[questionnaireId];
            const answerMap =
              answerMaps &&
              answerMaps.find(
                (
                  itemAnswerMap: FhirQuestionnaireResponseMod.QuestionnaireAnswerMap
                ) => {
                  const currentQuestId = itemAnswerMap.questionId;
                  const currentLinkId = questResItem.linkId;
                  return currentQuestId === currentLinkId;
                }
              );

            if (answerMap) {
              printableItem.responseExplanation = answerMap?.answerDisplay;
            }

            if (item.valueInteger !== undefined) {
              printableItem.response += String(item.valueInteger);
            } else if (item.valueCoding !== undefined) {
              printableItem.response += String(item.valueCoding.display);
            } else if (item.valueString !== undefined) {
              printableItem.response += item.valueString;
            }
          }
        );

        printableItems.push(printableItem);
      }

      if (questResItem.item) {
        const rList = getPrintableQuestionnaireResponseItems(
          questResItem.item,
          questionnaireId
        );
        const mergedList = printableItems.concat(rList);
        printableItems = mergedList;
      }
    }

    return printableItems;
  };

  const getStratifiersNQuestsFromMRGroup = (
    group: FhirMeasureReportMod.Group
  ) => {
    const questResSplitRef =
      group.code?.coding?.[0].code === undefined
        ? undefined
        : getSplitReference(group.code?.coding[0].code);

    const questRes =
      questResSplitRef?.uuid === undefined ||
      indexedfhirQuestionnaireResponses === undefined
        ? undefined
        : indexedfhirQuestionnaireResponses[questResSplitRef?.uuid];

    const matchingQuestUuidFromUrl = (() => {
      if (questRes?.questionnaire === undefined) return undefined;

      const url = questRes?.questionnaire;
      const regex1 = /(Questionnaire\/.+)/g;
      const matchedValues = url.match(regex1);

      if (matchedValues?.[0] !== undefined) {
        return getSplitReference(matchedValues[0]).uuid;
      }

      return undefined;
    })();

    const quest =
      matchingQuestUuidFromUrl === undefined ||
      indexedFhirQuestionnaires === undefined
        ? undefined
        : indexedFhirQuestionnaires[matchingQuestUuidFromUrl];

    let printableStratifiers: PrintableTaskMod.Stratifier[] = [];

    group.stratifier?.forEach((item: FhirMeasureReportMod.Stratifier) => {
      item.stratum?.forEach((item2: FhirMeasureReportMod.Stratum) => {
        printableStratifiers.push(getStratumData(item2));
      });
    });

    let printableQuestionnaireResponse: PrintableTaskMod.QuestionnaireResponse = {
      text:
        quest?.title === undefined
          ? "Questionnaire Response"
          : quest?.title + " Questionnaire Response",
      items:
        questRes?.item === undefined || quest?.id === undefined
          ? []
          : getPrintableQuestionnaireResponseItems(questRes?.item, quest?.id),
    };
    let printableMeasureReportGroup: PrintableTaskMod.MeasureReport = {
      stratum_text: group.code?.text === undefined ? "" : group.code?.text,
      stratifiers: printableStratifiers,
    };

    if (showMeasureQR) {
      printableMeasureReportGroup.questionnaire_response = printableQuestionnaireResponse;
    }

    return printableMeasureReportGroup;
  };

  const getPrintableMeasureReportGroups = (
    groups: FhirMeasureReportMod.Group[]
  ) => {
    let printableMeasureGroup: PrintableTaskMod.MeasureReport[] = [];

    groups.forEach((item: FhirMeasureReportMod.Group) => {
      printableMeasureGroup.push(getStratifiersNQuestsFromMRGroup(item));
    });

    return printableMeasureGroup;
  };

  const getRiskByMeasureScore = (score: any) => {
    let riskCode: Risks = "none";
    switch (score) {
      case 1:
        riskCode = "low-risk";
        break;
      case 2:
        riskCode = "medium-risk";
        break;
      case 3:
        riskCode = "high-risk";
        break;
      default:
        riskCode = "none";
    }
    return riskCode;
  };

  const getComplexityScoreRiskColorPalette = (
    riskCode: Risks | undefined,
    group?: FhirMeasureReportMod.Group
  ) => {
    const riskValue =
      group?.stratifier?.[0]?.stratum?.[0]?.component?.[0]?.code?.coding?.[0]
        ?.code;
    const riskCodeParsed =
      riskCode === undefined
        ? riskValue === undefined
          ? getRiskByMeasureScore(group?.stratifier?.[0].stratum?.[0].measureScore?.value)
          : getRisk(riskValue)
        : riskCode;

    return getRiskColorPalette(riskCodeParsed);
  };

  useEffect(() => {
    if (
      measureReport === undefined ||
      indexedFhirQuestionnaires === undefined ||
      indexedfhirQuestionnaireResponses === undefined
      // hasLoadedAnswerMaps === false
    )
      return;

    setPrintableTask((prev) => {
      const mrGroups: MeasureReportGroups = getMeasureReportGroups(
        measureReport
      );

      const medicalComplexityRiskColorP = getComplexityScoreRiskColorPalette(
        undefined,
        mrGroups.medicalComplex
      );

      const printableMedicalComplexity: PrintableTaskMod.ComplexityScore = {
        color: medicalComplexityRiskColorP.internalBackgroundColor,
        value:
          mrGroups.medicalComplex?.stratifier?.[0].stratum?.[0].measureScore
            ?.value === undefined
            ? ""
            : String(
                mrGroups.medicalComplex?.stratifier?.[0].stratum?.[0]
                  .measureScore?.value
              ),
      };

      const socialComplexityRiskColorP = getComplexityScoreRiskColorPalette(
        undefined,
        mrGroups.socialComplex
      );

      const printableSocialMedicalComplexity: PrintableTaskMod.ComplexityScore = {
        color: socialComplexityRiskColorP.internalBackgroundColor,
        value:
          mrGroups.socialComplex?.stratifier?.[0].stratum?.[0].measureScore
            ?.value === undefined
            ? ""
            : String(
                mrGroups.socialComplex?.stratifier?.[0].stratum?.[0]
                  .measureScore?.value
              ),
      };

      const serviceIntegrationLevelsRiskColorP = getComplexityScoreRiskColorPalette(
        undefined,
        mrGroups.serviceIntegration
      );

      const printableServiceIntegrationLevels: PrintableTaskMod.ComplexityScore = {
        color: serviceIntegrationLevelsRiskColorP.internalBackgroundColor,
        value:
          mrGroups.serviceIntegration?.stratifier?.[0].stratum?.[0].measureScore
            ?.value === undefined
            ? ""
            : String(
                mrGroups.serviceIntegration?.stratifier?.[0].stratum?.[0]
                  .measureScore?.value
              ),
      };

      return {
        ...prev,
        request: {
          ...prev.request,
          task_completion_date: parseDateUsingFormat(measureReport?.period?.end, 'EEE MMM dd yyyy') || "",
        },
        medical_complexity: printableMedicalComplexity,
        social_complexity: printableSocialMedicalComplexity,
        service_integration_levels: printableServiceIntegrationLevels,
        measure_report:
          mrGroups.normal === undefined
            ? []
            : getPrintableMeasureReportGroups(mrGroups.normal),
      };
    });
  }, [
    measureReport,
    indexedFhirQuestionnaires,
    indexedfhirQuestionnaireResponses,
  ]);

  const getQuestionnaireFromQuestRes = (
    questRes: FhirQuestionnaireResponseMod.QuestionnaireResponse
  ) => {
    const matchingQuestUuidFromUrl = (() => {
      if (questRes?.questionnaire === undefined) return undefined;

      const url = questRes?.questionnaire;
      const regex1 = /(Questionnaire\/.+)/g;
      const matchedValues = url.match(regex1);

      if (matchedValues?.[0] !== undefined) {
        return getSplitReference(matchedValues[0]).uuid;
      }

      return undefined;
    })();

    const quest =
      matchingQuestUuidFromUrl === undefined ||
      indexedFhirQuestionnaires === undefined
        ? undefined
        : indexedFhirQuestionnaires[matchingQuestUuidFromUrl];
    return quest;
  };

  const getPrintableQuestionnaireResponseList = (
    indexedfhirQuestionnaireResponses: indexedInt<FhirQuestionnaireResponseMod.QuestionnaireResponse>
  ) => {
    let printableQuestionnaireResponseList: PrintableTaskMod.QuestionnaireResponse[] = [];

    Object.keys(indexedfhirQuestionnaireResponses).forEach((key: string) => {
      const questRes = indexedfhirQuestionnaireResponses[key];
      const quest = getQuestionnaireFromQuestRes(questRes);
      const printableQuestionnaireResponse: PrintableTaskMod.QuestionnaireResponse = {
        text:
          quest?.title === undefined
            ? "Questionnaire Response"
            : quest?.title + " Questionnaire Response",
        items:
          questRes?.item === undefined || quest?.id === undefined
            ? []
            : getPrintableQuestionnaireResponseItems(questRes?.item, quest.id),
      };
      printableQuestionnaireResponseList.push(printableQuestionnaireResponse);
    });

    return printableQuestionnaireResponseList;
  };

  useEffect(() => {
    if (
      indexedFhirQuestionnaires === undefined ||
      indexedfhirQuestionnaireResponses === undefined ||
      indexedAnswerMaps === undefined
    )
      return;

    setPrintableTask((prev) => {
      return {
        ...prev,
        questionnaire_response_list: getPrintableQuestionnaireResponseList(
          indexedfhirQuestionnaireResponses
        ),
      };
    });
  }, [
    indexedFhirQuestionnaires,
    indexedfhirQuestionnaireResponses,
    indexedAnswerMaps,
  ]);

  useEffect(() => {
    if (consent === undefined) return;
    setPrintableTask((prev) => {
      return {
        ...prev,
        consent: consent,
      };
    });
  }, [consent]);

  return printableTask;
}
