import { FhirDataTypesMod } from "models/fhirDataTypes";
import { FhirMeasureReportMod } from "models/measureReport";
import { FhirPatientMod } from "models/patient";
import { FhirTaskMod } from "models/task";
import { MeasureReportGroups } from "../pages/orders/RequestReport";
import { get } from "../api/agent";
import { urlBackNestApp } from "routes/urls";
import { ContactPoint, Identifier, Period, Reference } from "types/Fhir";
import {
  NPI_IDENTIFIER_SYSTEM,
  PROVIDER_CERTIFICATION_IDENTIFIER,
  PROVIDER_LICENSE_IDENTIFIER,
  PROVIDER_LINK_IDENTIFIER,
  PROVIDER_SPECIALTY_IDENTIFIER,
} from "constants/practitioner";
import moment from "moment";
import {
  Practitioner,
  PractitionerQualification,
  QualificationType,
  SimpleLicense,
  SimpleQualification,
} from "types/Practitioner";
import { calculateAge } from "./datesFormats";
import { PROVIDER_CLASSIFICATION_IDENTIFIER_CODING_CODE } from "./config";
import { v4 as uuidv4 } from "uuid";

interface SplitReference {
  resourceType: string;
  uuid: string;
}

/**
 * Performs a validation if there is a string matching the pattern ResourceName/uuid in the last part of the string then returns the matching string
 * @param value a string that could be and url that points to a resource or a resource reference
 */
export function getResourceRef(value: string) {
  const regexFhirResourceRef = /([A-z]+)(\/){1}([\w\-]+)$/g;

  if (regexFhirResourceRef.test(value)) {
    const matchedValues = value.match(regexFhirResourceRef);

    if (matchedValues?.[0] !== undefined) {
      return matchedValues[0];
    }
  }

  return undefined;
}

export function getSplitReference(value: string): SplitReference {
  if (!value) return null;
  const splitedValue = value.split("/");
  const splitRe: SplitReference = {
    resourceType: splitedValue[0],
    uuid: splitedValue[1],
  };

  return splitRe;
}

export function getTaskNumber(task: FhirTaskMod.Task) {
  const taskNumber = task.identifier?.find(
    (value: FhirDataTypesMod.Identifier) =>
      value.type?.text === "NJ InCK system task number"
  )?.value;

  return taskNumber === undefined ? "" : taskNumber;
}

function getSystemValueFromIdentifiers(
  list: FhirDataTypesMod.Identifier[],
  system: string | undefined
) {
  if (system === undefined) return undefined;
  if (!list) return "No exist";
  return list.find((value: FhirDataTypesMod.Identifier) => {
    // console.log("getSystemValueFromIdentifiers( find: value", value.system);
    // console.log("getSystemValueFromIdentifiers( find: system:", system);
    // console.log(
    //   "getSystemValueFromIdentifiers( match:",
    //   value.system === system
    // );
    return value.system === system;
  })?.value;
}

export function getMRNFromIdentifiers(list: FhirDataTypesMod.Identifier[]) {
  const system = process.env.REACT_APP_SYSTEM_MRN;
  return getSystemValueFromIdentifiers(list, system);
}

export function getSocialSecurityNumberFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_SOCIAL_SECURITY_NUMBER;
  return getSystemValueFromIdentifiers(list, system);
}

export function getBirthCertificateNumberFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_SYSTEM_BIRTH_CERTIFICATE_NUMBER;
  return getSystemValueFromIdentifiers(list, system);
}

export function getDriverLicenseNumberFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_DRIVER_LICENSE_NUMBER;
  return getSystemValueFromIdentifiers(list, system);
}

export function getServiceRequestNumberFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_SYSTEM_SERVICE_REQUEST_NUMBER;
  return getSystemValueFromIdentifiers(list, system);
}

export function getTaskNumberFromIdentifiers2(
  list: FhirDataTypesMod.Identifier[] = []
) {
  return list.find(
    (value: FhirDataTypesMod.Identifier) =>
      value.system?.slice(-18) === "njinck-task-number"
  )?.value;
}

export function getTaskNumberFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_SYSTEM_TASK_NUMBER;
  return getSystemValueFromIdentifiers(list, system);
}

export function getKeycloakIDFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_SYSTEM_KEYCLOAK_ID;
  return getSystemValueFromIdentifiers(list, system);
}

export function getNPIFromIdentifiers(list: FhirDataTypesMod.Identifier[]) {
  const system = process.env.REACT_APP_SYSTEM_NPI;
  return getSystemValueFromIdentifiers(list, system);
}

export function getMedicAidFromIdentifiers(
  list: FhirDataTypesMod.Identifier[]
) {
  const system = process.env.REACT_APP_SYSTEM_MEDICAID;
  // console.log("getMedicAidFromIdentifiers( system", system);
  // console.log("getMedicAidFromIdentifiers( list", list);
  return getSystemValueFromIdentifiers(list, system);
}

function getSystemValueFromContactPoints(
  list: FhirDataTypesMod.ContactPoint[],
  system: string | undefined
) {
  if (system === undefined) return undefined;

  return list.find((item: FhirDataTypesMod.ContactPoint) => {
    return item.system === system;
  })?.value;
}

export function getPhoneFromTelecom(list: FhirDataTypesMod.ContactPoint[]) {
  const system = "phone";
  return getSystemValueFromContactPoints(list, system);
}

export function getEmailFromTelecom(list: FhirDataTypesMod.ContactPoint[]) {
  const system = "email";
  return getSystemValueFromContactPoints(list, system);
}

function filterContactPointsWhereSystem(
  list: FhirDataTypesMod.ContactPoint[],
  system: string | undefined
) {
  if (system === undefined || list === undefined) return undefined;

  let filteredList: FhirDataTypesMod.ContactPoint[] = [];

  list.forEach((item: FhirDataTypesMod.ContactPoint) => {
    if (item.system !== undefined && item.system === system)
      filteredList.push(item);
  });

  return filteredList;
}

export function getPhonesFromTelecom(list: FhirDataTypesMod.ContactPoint[]) {
  const system = "phone";
  return filterContactPointsWhereSystem(list, system);
}

export function getEmailsFromTelecom(list: FhirDataTypesMod.ContactPoint[]) {
  const system = "email";
  return filterContactPointsWhereSystem(list, system);
}

export function getRefFromInOutPut(
  item: FhirTaskMod.Output | FhirTaskMod.Input
) {
  if (item.valueReference?.reference !== undefined) {
    return getSplitReference(item.valueReference.reference);
  } else if (item.valueUri !== undefined) {
    return getSplitReference(item.valueUri);
  }

  return undefined;
}

function getValueTypeFromPatientLink(
  patient: FhirPatientMod.Patient,
  matchingSystem: string
) {
  if (patient.link === undefined) return undefined;

  for (let i = 0; i < patient.link?.length; i++) {
    const item = patient.link[i];

    // console.log("DEBUG getValueTypeFromPatientLink: ", item);

    if (
      item?.other?.type !== undefined &&
      item?.other?.type === matchingSystem
    ) {
      return item?.other?.display;
    }
  }

  return undefined;
}

export async function getResource(path: string, code: string) {
  const url = `${urlBackNestApp}/fhirproxy/${path}`;
  let getDisplay = [];

  try {
    const fetchFhirResource: any = await get(url);
    getDisplay =
      fetchFhirResource?.compose?.include?.[0]?.concept ||
      fetchFhirResource?.concept;
  } catch (err) {
    console.log("error on getValueSetDisplay function", err);
  }

  return getDisplay?.find((i: any) => i.code === code).display || "";
}

export async function getValueTypeFromPatientExtension(
  patient: FhirPatientMod.Patient,
  matchingSystem: string
) {
  if (patient.extension === undefined) return undefined;
  for (let i = 0; i < patient.extension?.length; i++) {
    const item = patient.extension[i];
    const value = item?.valueCodeableConcept as FhirDataTypesMod.CodeableConcept;

    if (item?.url === matchingSystem) {
      if (value?.text) {
        return value?.text;
      } else if (value?.coding?.[0].display) {
        return value?.coding?.[0].display;
      } else if (value?.coding?.[0]?.code) {
        const display = await getResource(item?.url, value?.coding?.[0]?.code);
        if (!display) return value?.coding?.[0]?.code;
        return display;
      }
    }
  }

  return undefined;
}

export function getRaceFromPatientDeprecated(patient: FhirPatientMod.Patient) {
  const system = "CodeSystem/8c898f7c-f057-4d3d-809f-ee675e43bcd7";
  return getValueTypeFromPatientLink(patient, system);
}

export function getEthnicityFromPatientDeprecated(
  patient: FhirPatientMod.Patient
) {
  const system = "CodeSystem/b131f368-6a6e-4de4-82a5-5768e24efeb4";
  return getValueTypeFromPatientLink(patient, system);
}

export async function getValueSetDisplay(path: string, code: string) {
  const url = `${urlBackNestApp}/fhirproxy/${path}`;
  let getDisplay = [];

  try {
    const fetchFhirResource: any = await get(url);
    getDisplay = fetchFhirResource?.compose?.include?.[0]?.concept;
  } catch (err) {
    console.log("error on getValueSetDisplay function", err);
  }

  return getDisplay?.find((i: any) => i.code === code).display || "";
}

export async function getRaceFromPatientValueset(
  patient: FhirPatientMod.Patient
) {
  const system = `ValueSet/${process.env.REACT_APP_RACE_VALUESET_ID}`;
  const result = await getValueTypeFromPatientExtension(patient, system);

  return result;
}

export async function getEthnicityFromPatientValueset(
  patient: FhirPatientMod.Patient
) {
  const system = `ValueSet/${process.env.REACT_APP_ETHNICITY_VALUESET_ID}`;
  const result = await getValueTypeFromPatientExtension(patient, system);

  return result;
}

export function getRaceFromPatient(patient: FhirPatientMod.Patient) {
  const result = getRaceFromPatientValueset(patient);
  return result;
}

export function getEthnicityFromPatient(patient: FhirPatientMod.Patient) {
  const result = getEthnicityFromPatientValueset(patient);
  return result;
}

export function getUuidsFromInOutPut(
  items: FhirTaskMod.Output[] | FhirTaskMod.Input[],
  resourceType: string
): string[] {
  const uuids: string[] = [];

  items.forEach((item: FhirTaskMod.Output | FhirTaskMod.Input) => {
    const splitRef = getRefFromInOutPut(item);
    if (splitRef !== undefined && splitRef.resourceType === resourceType) {
      uuids.push(splitRef.uuid);
    }
  });

  return uuids;
}

function isSystemInCoding(
  list: FhirDataTypesMod.Coding[] | undefined,
  matchingSystem: string | undefined
) {
  if (matchingSystem === undefined || list === undefined) return false;

  for (let i = 0; i < list.length; i++) {
    if (list[i].system === matchingSystem) return true;
  }

  return false;
}

export function isMedicalComplexityGroup(group: FhirMeasureReportMod.Group) {
  // console.log("xmedicalComplex", group)
  const matchingSystem = process.env.REACT_APP_SYSTEM_MEDICAL_COMPLEXITY;
  return isSystemInCoding(group.code?.coding, matchingSystem);
}

export function isSocialComplexityGroup(group: FhirMeasureReportMod.Group) {
  // console.log("xsocialComplex", group)
  const matchingSystem = process.env.REACT_APP_SYSTEM_SOCIAL_COMPLEXITY;
  return isSystemInCoding(group.code?.coding, matchingSystem);
}

export function isServiceIntegrationLevelsGroup(
  group: FhirMeasureReportMod.Group
) {
  // console.log("serviceIntegrations", group)
  const matchingSystem =
    process.env.REACT_APP_SYSTEM_SERVICE_INTEGRATION_LEVELS;
  return isSystemInCoding(group.code?.coding, matchingSystem);
}

export const isMedicalObservationGroup = (group: any) => {
  const matchingSystem = process.env.REACT_APP_SYSTEM_MEDICAL_COMPLEXITY;

  return group?.resource?.code?.coding?.[0]?.system === matchingSystem;
};
export const isSocialObservationGroup = (group: any) => {
  const matchingSystem = process.env.REACT_APP_SYSTEM_SOCIAL_COMPLEXITY;

  return group?.resource?.code?.coding?.[0]?.system === matchingSystem;
};
export const isServiceIntegrationLevelsObservationGroup = (group: any) => {
  const matchingSystem =
    process.env.REACT_APP_SYSTEM_SERVICE_INTEGRATION_LEVELS;

  return group?.resource?.code?.coding?.[0]?.system === matchingSystem;
};

export function getMeasureReportGroups(
  measureReport: FhirMeasureReportMod.MeasureReport
) {
  // console.log("measureReport", measureReport)
  let res: MeasureReportGroups = {
    medicalComplex: undefined,
    socialComplex: undefined,
    serviceIntegration: undefined,
    normal: [],
  };

  measureReport.group?.forEach((item: FhirMeasureReportMod.Group) => {
    if (isMedicalComplexityGroup(item)) {
      res.medicalComplex = item;
    } else if (isSocialComplexityGroup(item)) {
      res.socialComplex = item;
    } else if (isServiceIntegrationLevelsGroup(item)) {
      res.serviceIntegration = item;
    } else {
      res.normal?.push(item);
    }
  });

  return res;
}

export function getObservationGroups(observations: any) {
  let res: any = {
    medicalComplex: undefined,
    socialComplex: undefined,
    serviceIntegration: undefined,
    normal: [],
  };

  observations?.entry?.forEach((item: any) => {
    if (isMedicalObservationGroup(item)) {
      res.medicalComplex = item;
    } else if (isSocialObservationGroup(item)) {
      res.socialComplex = item;
    } else if (isServiceIntegrationLevelsObservationGroup(item)) {
      res.serviceIntegration = item;
    } else {
      res.normal?.push(item);
    }
  });

  return res;
}

export function isPatientInCKEligible(
  fhirPatient: FhirPatientMod.Patient | undefined
): boolean {
  return (
    fhirPatient &&
    fhirPatient.meta?.tag &&
    fhirPatient.meta?.tag.some(
      (i: FhirDataTypesMod.Coding) => i?.code && i?.code === "InCK-Eligible"
    )
  );
}

export const getNPIFromIdentifier = (identifiers: Identifier[]): string =>
  identifiers.find(({ system }) => system === NPI_IDENTIFIER_SYSTEM)?.value ??
  "";

export const getTelecomValues = (
  system: ContactPoint["system"],
  telecom: ContactPoint[]
): string[] =>
  telecom
    .filter((telecom) => telecom.system === system)
    .map(({ value }) => value ?? "") ?? [];

export const checkIfIdentifierPeriodIsValid = ({
  start,
  end,
}: Period): boolean => {
  const periodStart = moment(start ?? null);

  const periodEnd = moment(end ?? "9999-12-31");

  return !!(
    periodStart.isValid() &&
    periodEnd.isValid() &&
    moment().isBetween(periodStart, periodEnd, undefined, "[]")
  );
};

export const verifyIfPractitionerIsVerified = (
  practitioner: Practitioner | undefined | null
): boolean => {
  const providerDirectoryIdentifiers = getValidProviderDirectoryLinkIdentifiers(
    practitioner?.identifier ?? []
  );
  return !!providerDirectoryIdentifiers.length;
};

export const getValidProviderDirectoryLinkIdentifiers = (
  identifiers: Identifier[]
): Identifier[] => {
  const providerDirectoryLinks =
    identifiers?.filter?.(
      ({ type, system }) =>
        type?.coding?.[0]?.code ===
          PROVIDER_LINK_IDENTIFIER.type.coding[0].code &&
        system === PROVIDER_LINK_IDENTIFIER.system
    ) ?? [];

  return providerDirectoryLinks.reduce<Identifier[]>(
    (validProviderDirectoryLinks, providerDirectoryLink) => {
      const isVerified = checkIfIdentifierPeriodIsValid(
        providerDirectoryLink?.period ?? {}
      );
      if (isVerified)
        return [...validProviderDirectoryLinks, providerDirectoryLink];
      return validProviderDirectoryLinks;
    },
    []
  );
};

export const getPractitionerProviderDirectoryId = (
  practitioner: Practitioner | undefined | null
): string | null => {
  const providerDirectoryIdentifiers = getValidProviderDirectoryLinkIdentifiers(
    practitioner?.identifier ?? []
  );
  if (providerDirectoryIdentifiers?.[0]?.value)
    return providerDirectoryIdentifiers[0].value;

  return null;
};

export const getTelecoms = (
  system: ContactPoint["system"],
  telecom: ContactPoint[]
): ContactPoint[] => telecom.filter((telecom) => telecom.system === system);

export const getFormattedDatePeriod = (
  period: Period | null | undefined
): string => {
  let formattedDate = "";
  if (!period) return formattedDate;
  const dateFormat = "MMM YYYY";
  const { start, end } = period ?? {};
  const startDate = moment(start ?? null);
  const endDate = moment(end ?? null);
  if (startDate.isValid()) {
    formattedDate = startDate.format(dateFormat);
    formattedDate += ` to ${
      endDate.isValid() ? endDate.format(dateFormat) : "Current"
    }`;
  }

  return formattedDate;
};

export const getQualificationTypeFromIdentifier = (
  code: string,
  display: string
): SimpleQualification["type"] => {
  switch (code) {
    case PROVIDER_CERTIFICATION_IDENTIFIER.type.coding[0].code:
      return QualificationType.CERTIFICATION;
    case PROVIDER_LICENSE_IDENTIFIER.type.coding[0].code:
      return QualificationType.LICENSE;
    case PROVIDER_CLASSIFICATION_IDENTIFIER_CODING_CODE:
      return QualificationType.TAXONOMY;
    default:
      return display ?? "Other";
  }
};

export const getFormattedPersonDOB = (date: moment.Moment) => {
  if (!date.isValid()) return "";
  const age = calculateAge(date);
  return `${date.format("MMM DD, YYYY")} (${age} yrs)`;
};

export const getSimpleQualifications = (
  qualifications: PractitionerQualification[]
): SimpleQualification[] =>
  qualifications.map(({ identifier, code, period }) => ({
    type: getQualificationTypeFromIdentifier(
      identifier?.[0]?.type?.coding?.[0]?.code ?? "",
      identifier?.[0]?.type?.coding?.[0]?.display ?? ""
    ),
    code: code?.coding?.[0]?.code ?? "",
    id: uuidv4(),
    name: code?.coding?.[0]?.display ?? "",
    period: getFormattedDatePeriod(period),
    sortingDate: getSortingDateFromPeriod(period),
  }));

export const getSimpleLicenses = (identifiers: Identifier[]): SimpleLicense[] =>
  identifiers.map(({ type, period, value }) => ({
    id: uuidv4(),
    code: type?.coding?.[0]?.code ?? "",
    title: type?.coding?.[0]?.display ?? type?.text ?? "",
    period: getFormattedDatePeriod(period),
    number: value ?? "",
    sortingDate: getSortingDateFromPeriod(period),
  }));

export const getSortingDateFromPeriod = (period: Period | undefined | null) => {
  const { start, end } = period ?? {};
  const startDate = moment(start ?? null);
  const endDate = moment(end ?? null);
  if (startDate.isValid()) return startDate;
  return endDate;
};

export const getValuesFromReference = (
  reference: string | null | undefined
): [string | null, string | null] => {
  if (!reference) return [null, null];
  const splitted = reference.split("/");
  return [splitted?.[0] ?? null, splitted?.[1] ?? null];
};

export const getReferencesIdByResourceType = (
  references: Reference[],
  resourceType: string
): string[] =>
  references.reduce<string[]>((resourceIds, { reference }) => {
    const { resourceType: type, uuid } = getSplitReference(reference ?? "");
    if (type === resourceType && uuid) return [...resourceIds, uuid];
    return resourceIds;
  }, []);

export const getOwnerTask = (identifiers: FhirDataTypesMod.Identifier[]) => {
  return identifiers.filter((item) => item?.type?.text === "User");
};